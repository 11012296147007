import { useState, useEffect } from "react";
import { getCurrentForm } from "../services/api";
import { countFeedback } from "../services/api";

const Count = () => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [atelier, setAtelier] = useState(null);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    getCurrentForm()
      .then((response) => {
        setAtelier(response);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setErrors(error.message);
      });
  }, []);

  useEffect(() => {
    const fetchCount = async () => {
      try {
        const response = await countFeedback();
        console.log(response);
        setCount(response.data);
        // if (response.status === 200) {
        //   setCount(response.data.count);
        // } else {
        //   console.log("Failed to fetch count.");
        // }
      } catch (error) {
        // alert("An error occurred. Please try again later.");
        console.error(error);
      }
      // setCount(25);
    };

    const interval = setInterval(() => {
      fetchCount();
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          backgroundColor: "grey",
        }}
      >
        Loading...
      </div>
    );
  }

  return (
    <div className="max-w-md mx-auto px-4 py-8 mt-10 text-center">
      <h1 className="text-2xl font-medium mb-4">
        Grand Atelier du Digital 2023
      </h1>
      {atelier ? (
        <div>
          <h2 className="text-xl font-medium mb-2">
            Atelier: {atelier.data.nom}
          </h2>
          <p className="mt-4 text-center">
            <span className="text-3xl font-bold">{count}</span> avis reçus.
          </p>
        </div>
      ) : (
        <div>
          <div className="bg-red-100 border border-dashed border-red-400 text-red-700 px-4 py-3 rounded mb-4 mt-4">
            {errors || "Une erreur est survenue!"}
          </div>
        </div>
      )}
    </div>
  );
};

export default Count;
