import axios from "axios";

export const BASE_URL = "https://yolim.sandbox.gouv.tg/api";

const api = axios.create({
  baseURL: BASE_URL,
});

export const handleSubmission = async (data) => {
  // return data;
  try {
    const res = await api.post("/feedback", data);
    return res.data;
  } catch (error) {
    console.log("STORE ERROR", error);
    return false;
  }
};

export const getCurrentForm = async () => {
 try {
  const res = await api.get(`/getCurrentAtelier/`);

  return res.data;
 } catch (error) {
  console.log("GET CURRENT AT ERROR", error);
  return false;
 }
};

export const countFeedback = async () => {
  return (await api.get(`/getCurrentFeedbackCount`)).data;
};
