import { useState, useRef, useEffect } from "react";
import { handleSubmission } from "../services/api";

const questions = [
  {
    id: 1,
    text: "Comment évalueriez-vous la clarté des présentations de cette session ?",
    type: "scale",
    options: [
      { symbol: "😄", value: 5, text: "Très claire" },
      { symbol: "😊", value: 4, text: "Claire" },
      { symbol: "😶", value: 3, text: "Pas d’avis" },
      { symbol: "😕", value: 2, text: "Peu claire" },
      { symbol: "🙁", value: 1, text: "Pas du tout claire" },
    ],
    hasFollowup: false,
    comment: true,
  },
  {
    id: 2,
    text: "Comment évalueriez-vous votre compréhension du contenu à la suite de la présentation ?",
    type: "scale",
    options: [
      { symbol: "😄", value: 5, text: "J’ai tout compris" },
      { symbol: "😊", value: 4, text: "J’ai compris l’essentiel" },
      { symbol: "😶", value: 3, text: "Pas d’avis" },
      {
        symbol: "😕",
        value: 2,
        text: " J’ai besoin de plus d’explications",
      },
      { symbol: "🙁", value: 1, text: "Je n’ai rien compris" },
    ],
    hasFollowup: false,
    comment: true,
  },
  {
    id: 3,
    text: "Pensez-vous que le contenu de la présentation était instructif ?",
    type: "scale",
    options: [
      { symbol: "😄", value: 5, text: "Très instructif" },
      { symbol: "😊", value: 4, text: "Instructif" },
      { symbol: "😶", value: 3, text: "Pas d’avis" },
      { symbol: "😕", value: 2, text: "Peu instructif" },
      { symbol: "🙁", value: 1, text: "Pas du tout instructif" },
    ],
    hasFollowup: false,
    comment: true,
  },
  {
    id: 4,
    text: "Y a-t-il des aspects de cette session que vous souhaiteriez voir améliorés ? (Tant au niveau de la manière dont c’est présenté que du contenu)",
    type: "yes-no",
    options: [
      { symbol: "😊", value: 1, text: "Oui" },
      { symbol: "😕", value: 2, text: "Non" },
    ],
    hasFollowup: true,
    comment: false,
  },
  {
    id: 5,
    text: "Avez-vous un profil technique ?",
    type: "yes-no",
    options: [
      { symbol: "😊", value: 1, text: "Oui" },
      { symbol: "😕", value: 2, text: "Non" },
    ],
    hasFollowup: false,
    comment: false,
  },
  {
    id: 6,
    text: "De quelle administration venez-vous ?",
    type: "yes-no",
    options: [
      { symbol: "😶", value: 1, text: "Administration publique" },
      { symbol: "😶", value: 2, text: "Institution privée" },
      { symbol: "😶", value: 3, text: "Société civile" },
      { symbol: "😶", value: 4, text: "Partenaire technique et financier" },
    ],
    hasFollowup: false,
    comment: false,
  },
];

const Formulaire = ({ atelier }) => {
  const [feedback, setFeedback] = useState({});
  const [errors, setErrors] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const errorRef = useRef(null);

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    const storedDate = localStorage.getItem("submissionDate");
    if (storedDate === today) {
      const atelierSession = localStorage.getItem("atelierId");
      if (atelierSession === atelier._id) {
        setIsSubmitted(true);
      }
    }
  }, []);

  const atelierId = atelier._id;

  // const atelierSession = localStorage.getItem("atelierId");

  const handleOptionClick = (event) => {
    const { name, value } = event.target;
    // const questionId = name.replace("question", "");

    // const question = questions.find((q) => q.id === parseInt(questionId, 10));

    let feedbackValue = parseInt(value, 10);

    // if (question.type === "yes-no" && feedbackValue === 1) {
    //   feedbackValue = { hasFollowup: true };
    // }

    setFeedback({
      ...feedback,
      [name]: feedbackValue,
    });
  };

  const handleFollowupChange = (event) => {
    const { name, value } = event.target;
    setFeedback({
      ...feedback,
      [name]: value,
    });
  };

  const handleCommentChange = (event) => {
    const { name, value } = event.target;
    setFeedback({
      ...feedback,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const validationErrors = [];

    questions.forEach((question) => {
      if (!feedback[`question${question.id}`]) {
        validationErrors.push(`Question ${question.id} est obligatoire.`);
      }
    });

    if (validationErrors.length) {
      setErrors(validationErrors);
      if (errorRef.current) {
        errorRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }

    if (validationErrors.length === 0) {
      // Submit form
      try {
        console.log(feedback);
        const res = await handleSubmission({
          ...feedback,
          atelier: atelier._id,
          lang: "fr",
        });

        console.log("RES API", res);
        if (!res) {
          alert("Une erreur est survenue");
        } else {
          // localStorage.setItem("atelierId", atelierId);
          // setIsSubmitted(true);
          const today = new Date().toISOString().split("T")[0];
          localStorage.setItem("atelierId", atelierId);
          localStorage.setItem("submissionDate", today);
          setIsSubmitted(true);
          alert("Formulaire soumis avec succès");
          setFeedback({});
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div>
      {isSubmitted ? (
        <div className="bg-green-100 border border-dashed border-green-400 text-green-700 px-4 py-3 rounded mb-4 mt-4">
          Votre avis sur cette session a été bien enregistré. Merci! 😊
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          {errors.length > 0 && (
            <div
              className="bg-red-100 border border-dashed border-red-400 text-red-700 px-4 py-3 rounded mb-4 mt-2"
              ref={errorRef}
            >
              {errors.map((error) => (
                <div key={error}>{error}</div>
              ))}
            </div>
          )}

          <div>
            
          </div>

          {questions.map((question) => (
            <div key={question.id} className="mb-4">
              <label className="block font-medium mb-2">
                {question.id}. {question.text}
              </label>
              <div className="flex flex-col">
                {question.options.map((option) => (
                  <label
                    key={option.value}
                    className="flex items-center cursor-pointer"
                  >
                    <input
                      type="radio"
                      name={`question${question.id}`}
                      id={`question${question.id}-${option.value}`}
                      value={option.value}
                      // checked={
                      //   feedback[`question${question.id}`] === option.value
                      // }
                      onChange={handleOptionClick}
                      className="sr-only"
                    />
                    <span
                      className={`inline-block rounded-full p-2 ${
                        feedback[`question${question.id}`] === option.value
                          ? "text-blue-500 text-2xl"
                          : ""
                      }`}
                    >
                      {option.symbol}
                    </span>
                    <span className="text-sm">{option.text}</span>
                  </label>
                ))}

                {question.comment && (
                  <div className="flex flex-col">
                    <label className="block font-medium mb-2">
                      Commentaire ? (Optionnel)
                    </label>
                    <textarea
                      className="border rounded py-2 px-3"
                      name={`comment${question.id}`}
                      onChange={handleCommentChange}
                      value={feedback[`comment${question.id}`] || ""}
                      placeholder="Pas plus de 200 caracters"
                    ></textarea>
                  </div>
                )}

                {question.hasFollowup &&
                  feedback[`question${question.id}`] === 1 && (
                    <div className="flex flex-col">
                      <label className="block font-medium mb-2">
                        Lesquels:
                      </label>
                      <input
                        type="text"
                        name={`followup${question.id}`}
                        value={feedback[`followup${question.id}`] || ""}
                        onChange={handleFollowupChange}
                        className="border rounded py-2 px-3"
                        required
                      />
                    </div>
                  )}
              </div>
            </div>
          ))}

          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Soumettre
          </button>
        </form>
      )}
    </div>
  );
};

export default Formulaire;
