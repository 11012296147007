import { useState, useRef, useEffect } from "react";
import { handleSubmission } from "../services/api";

const questions = [
  {
    id: 1,
    text: "How would you rate the clarity of the presentations in this session? ",
    type: "scale",
    options: [
      { symbol: "😄", value: 5, text: "Very clear" },
      { symbol: "😊", value: 4, text: "Clear" },
      { symbol: "😶", value: 3, text: "No opinion" },
      { symbol: "😕", value: 2, text: "Unclear" },
      { symbol: "🙁", value: 1, text: "Not at all clear" },
    ],
    hasFollowup: false,
    comment: true,
  },
  {
    id: 2,
    text: "How would you rate your understanding of the content from the presentation?",
    type: "scale",
    options: [
      { symbol: "😄", value: 5, text: "I understood everything" },
      { symbol: "😊", value: 4, text: "I got the gist" },
      { symbol: "😶", value: 3, text: "No opinion" },
      {
        symbol: "😕",
        value: 2,
        text: "I need more explanations",
      },
      { symbol: "🙁", value: 1, text: "I did not understand anything" },
    ],
    hasFollowup: false,
    comment: true,
  },
  {
    id: 3,
    text: "Do you think the content of the presentation was insightful?",
    type: "scale",
    options: [
      { symbol: "😄", value: 5, text: "Very likely" },
      { symbol: "😊", value: 4, text: "Probable" },
      { symbol: "😶", value: 3, text: "No opinion " },
      { symbol: "😕", value: 2, text: "Unlikely" },
      { symbol: "🙁", value: 1, text: "Not at all likely" },
    ],
    hasFollowup: false,
    comment: true,
  },
  {
    id: 3,
    text: "Are there any aspects of this session that you would like to see improved? (Both in terms of presentation and content)",
    type: "yes-no",
    options: [
      { symbol: "😊", value: 1, text: "Yes" },
      { symbol: "😕", value: 2, text: "No" },
    ],
    hasFollowup: true,
    comment: false,
  },
  {
    id: 4,
    text: "Do you have a technical profile?",
    type: "yes-no",
    options: [
      { symbol: "😊", value: 1, text: "Yes" },
      { symbol: "😕", value: 2, text: "No" },
    ],
    hasFollowup: false,
    comment: false,
  },
  {
    id: 5,
    text: "What entity do you come from?",
    type: "yes-no",
    options: [
      { symbol: "😶", value: 1, text: "Public administration" },
      { symbol: "😶", value: 2, text: "Private structure" },
      { symbol: "😶", value: 3, text: "Civil society" },
      { symbol: "😶", value: 4, text: "Financial and technical partner" },
    ],
    hasFollowup: false,
    comment: false,
  },
];

const Form = ({ atelier }) => {
  const [feedback, setFeedback] = useState({});
  const [errors, setErrors] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const errorRef = useRef(null);

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    const storedDate = localStorage.getItem("submissionDate");
    if (storedDate === today) {
      const atelierSession = localStorage.getItem("atelierId");
      if (atelierSession === atelier._id) {
        setIsSubmitted(true);
      }
    }
  }, []);

  const atelierId = atelier._id;

  const handleOptionClick = (event) => {
    const { name, value } = event.target;
    // const questionId = name.replace("question", "");

    // const question = questions.find((q) => q.id === parseInt(questionId, 10));

    let feedbackValue = parseInt(value, 10);

    // if (question.type === "yes-no" && feedbackValue === 1) {
    //   feedbackValue = { hasFollowup: true };
    // }

    setFeedback({
      ...feedback,
      [name]: feedbackValue,
    });
  };

  const handleFollowupChange = (event) => {
    const { name, value } = event.target;
    setFeedback({
      ...feedback,
      [name]: value,
    });
  };

  const handleCommentChange = (event) => {
    const { name, value } = event.target;
    setFeedback({
      ...feedback,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const validationErrors = [];

    questions.forEach((question) => {
      if (!feedback[`question${question.id}`]) {
        validationErrors.push(`Question ${question.id} is mandatory.`);
      }
    });

    if (validationErrors.length) {
      setErrors(validationErrors);
      if (errorRef.current) {
        errorRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }

    if (validationErrors.length === 0) {
      // Submit form
      try {
        const res = await handleSubmission({
          ...feedback,
          atelier: atelier._id,
          lang: "en"
        });

        console.log("RES API", res);
        if (!res) {
          alert("Une erreur est survenue");
        } else {
          // localStorage.setItem("atelierId", atelierId);
          // setIsSubmitted(true);
          const today = new Date().toISOString().split("T")[0];
          localStorage.setItem("atelierId", atelierId);
          localStorage.setItem("submissionDate", today);
          setIsSubmitted(true);
          alert("Form submitted successfully!");
          setFeedback({});
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="pb-3">
      {isSubmitted ? (
        <div className="bg-green-100 border border-dashed border-green-400 text-green-700 px-4 py-3 rounded mb-4 mt-4">
          Votre avis sur l'atelier a été bien enregistré. Merci! 😊
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          {errors.length > 0 && (
            <div
              className="bg-red-100 border border-dashed border-red-400 text-red-700 px-4 py-3 rounded mb-4 mt-2"
              ref={errorRef}
            >
              {errors.map((error) => (
                <div key={error}>{error}</div>
              ))}
            </div>
          )}

          {questions.map((question) => (
            <div key={question.id} className="mb-4">
              <label className="block font-medium mb-2">
                {question.id}. {question.text}
              </label>
              <div className="flex flex-col">
                {question.options.map((option) => (
                  <label
                    key={option.value}
                    className="flex items-center cursor-pointer"
                  >
                    <input
                      type="radio"
                      name={`question${question.id}`}
                      id={`question${question.id}-${option.value}`}
                      value={option.value}
                      // checked={
                      //   feedback[`question${question.id}`] === option.value
                      // }
                      onChange={handleOptionClick}
                      className="sr-only"
                    />
                    <span
                      className={`inline-block rounded-full p-2 ${
                        feedback[`question${question.id}`] === option.value
                          ? "text-blue-500 text-2xl"
                          : ""
                      }`}
                    >
                      {option.symbol}
                    </span>
                    <span className="text-sm">{option.text}</span>
                  </label>
                ))}

                {question.comment && (
                  <div className="flex flex-col">
                    <label className="block font-medium mb-2">
                      Any comment ? (Optional)
                    </label>
                    <textarea
                      className="border rounded py-2 px-3"
                      name={`comment${question.id}`}
                      onChange={handleCommentChange}
                      value={feedback[`comment${question.id}`] || ""}
                      placeholder="No more than 200 caracters"
                    ></textarea>
                  </div>
                )}

                {question.hasFollowup &&
                  feedback[`question${question.id}`] === 1 && (
                    <div className="flex flex-col">
                      <label className="block font-medium mb-2">Precise:</label>
                      <input
                        type="text"
                        name={`followup${question.id}`}
                        value={feedback[`followup${question.id}`] || ""}
                        onChange={handleFollowupChange}
                        className="border rounded py-2 px-3"
                        required
                      />
                    </div>
                  )}
              </div>
            </div>
          ))}

          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-3"
          >
            Submit
          </button>
        </form>
      )}
    </div>
  );
};

export default Form;
