import { useState, useEffect } from "react";
import Form from "../components/Form";
import { getCurrentForm } from "../services/api";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [atelier, setAtelier] = useState(null);
  const [errors, setErrors] = useState(null);



  useEffect(() => {
    getCurrentForm()
      .then((response) => {
        setAtelier(response);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setErrors(error.message);
      });
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          backgroundColor: "grey",
        }}
      >
        Loading...
      </div>
    );
  }

  return (
    <div className="max-w-md mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-2">
        Please share your opinions with us
      </h1>
      {atelier ? (
        <div>
          <h2 className="font-bold mb-1 uppercase">{atelier.data.nom}</h2>
          <Form atelier={atelier.data} />
        </div>
      ) : (
        <div>
          <div className="bg-red-100 border border-dashed border-red-400 text-red-700 px-4 py-3 rounded mb-4 mt-4">
            {errors || "Une erreur est survenue!"}
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
